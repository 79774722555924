/** A string that is branded by TypeScript to represent a `slug`. */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type Slug = string & { __brand: "Slug" };

/**
 * A Reg-Exp that matches a slug, i.e. groups of letters and numbers,
 * separated by hyphens.
 */
export const slugMatcher = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export function isValidSlug(s: string): s is Slug {
	return slugMatcher.test(s);
}
